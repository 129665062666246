import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { ToasterService } from '@core/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public header: any;
  public baseUrl;

  private authToken: any;
  private refreshToken: any;
  private uuid: any;

  constructor(
      public router: Router,
      public toaster: ToasterService
  ) {
    this.header = new Headers();
    this.baseUrl = environment.API_URL;
    this.authToken = this.getStoredAuthToken();
    this.refreshToken = this.getStoredRefreshToken();
    this.uuid = this.getStoredUuid();
  }

  setContentHeaders() {
    this.header.delete('Content-Type');
    this.header.append('Content-Type', 'application/json');
  }

  getStoredUuid() {
    return localStorage.getItem('uuid');
  }
  getStoredAuthToken() {
    return localStorage.getItem('access_token');
  }
  getStoredRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getUuid() {
    this.uuid = this.getStoredUuid();
    return this.uuid;
  }
  getAuthToken() {
    this.authToken = this.getStoredAuthToken();
    return this.authToken;
  }
  getRefreshToken() {
    this.refreshToken = this.getStoredRefreshToken();
    return this.refreshToken;
  }
  setUuid(uuid: any) {
    this.uuid = uuid;
    localStorage.setItem('uuid', uuid);
  }
  setAuthToken(token: any) {
    this.authToken = token;
    localStorage.setItem('access_token', token);
  }
  setRefreshToken(token: any) {
    this.refreshToken = token;
    localStorage.setItem('refresh_token', token);
  }

  clearTokens() {
    this.authToken = null;
    this.refreshToken = null;
    this.uuid = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('uuid');
  }

  saveTokens(dataObj: any) {
    const uuid = dataObj.user_id;
    const authToken = dataObj.access_token;
    const refreshToken = dataObj.refresh_token;
    this.setUuid(uuid);
    this.setAuthToken(authToken);
    this.setRefreshToken(refreshToken);
  }

  genericSuccess(data: any) {
    this.toaster.success(data.body.message);
    return data;
  }

  genericError(error: any) {
    let sError = '';
    try {
      sError = JSON.parse(error.error._body).error.message;
    } catch (e) {
      sError = 'Something went wrong. Please try again!';
    }
    this.toaster.error(sError);
    return throwError(sError);
  }

  loginErrorHndlr(error: any) {
    let sError: string;
    if (error.status === 422) {
      sError = 'This email is already taken up';
    } else if (error.status === 404) {
      sError = error.error.message;
    } else {
      sError = 'Oops! This combination is not valid';
    }
    return throwError(sError);
  }

}
