import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/base.service';
import { Constants } from '@shared/services/constants.service';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./toaster.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./cookie.service";
export class AuthService extends BaseService {
    constructor(router, toaster, http, cookie) {
        super(router, toaster);
        this.router = router;
        this.toaster = toaster;
        this.http = http;
        this.cookie = cookie;
    }
    login(userObj) {
        const url = this.baseUrl + Constants.apiEndPoints.signin;
        return this.http.post(url, userObj);
    }
    signup(userObj) {
        const url = this.baseUrl + Constants.apiEndPoints.signup;
        return this.http.post(url, userObj);
    }
    mailAuthenticate(token) {
        const url = this.baseUrl + Constants.apiEndPoints.authenticate;
        return this.http.post(url, { confirmation_token: token });
    }
    logout() {
        const url = this.baseUrl + Constants.apiEndPoints.signout;
        return this.http.delete(url);
    }
    isAuthenticated() {
        return (this.getAuthToken()) ? true : false;
    }
    isRegistered() {
        const cookieName = 'buildernow_registereduser';
        const result = this.cookie.getCookie(cookieName);
        return (result === null) ? false : true;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ToasterService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.CookieService)); }, token: AuthService, providedIn: "root" });
