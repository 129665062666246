/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "dialog-title primary-txt mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "dialog-content secondary-txt mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 11, "mat-dialog-actions", [["align", "end"], ["class", "dialog-action mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "cancel-btn secondary-txt"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(12, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(13, 0, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "button", [["cdkFocusInitial", ""], ["class", "highlight-btn primary-txt"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(18, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(19, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_7 = "no"; _ck(_v, 12, 0, currVal_7); var currVal_13 = "yes"; _ck(_v, 16, 0, currVal_13); var currVal_14 = "highlight-btn primary-txt"; var currVal_15 = _co.confirmClass; _ck(_v, 18, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.confirmHeaderTxt; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.msg; _ck(_v, 7, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11).disabled || null); var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 12).ariaLabel || null); var currVal_6 = i1.ɵnov(_v, 12).type; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.cancelBtnTxt; _ck(_v, 13, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 15).disabled || null); var currVal_10 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_11 = (i1.ɵnov(_v, 16).ariaLabel || null); var currVal_12 = i1.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_16 = _co.confirmBtnTxt; _ck(_v, 19, 0, currVal_16); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.ConfirmDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-dialog", i8.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, { confirmHeaderTxt: "confirmHeaderTxt", msg: "msg", cancelBtnTxt: "cancelBtnTxt", confirmBtnTxt: "confirmBtnTxt", confirmClass: "confirmClass" }, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
