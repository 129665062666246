<div class="dialog-container">
  <h2 mat-dialog-title class="dialog-title primary-txt">{{confirmHeaderTxt}}</h2>
  <mat-dialog-content class="mat-typography" class="dialog-content secondary-txt">
    <p>{{msg}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-action">
    <button mat-button [mat-dialog-close]="'no'" class="cancel-btn secondary-txt">{{cancelBtnTxt}}</button>
    <button mat-button [mat-dialog-close]="'yes'" cdkFocusInitial [ngClass]="confirmClass" class="highlight-btn primary-txt">{{confirmBtnTxt}}</button>
  </mat-dialog-actions>
</div>
