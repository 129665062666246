import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from './auth.service';

/**
 * Guard to prevent page access without authentication
 * @implements {CanActivate}
 */
@Injectable({
    providedIn: 'root'
})
export class AppGuardService implements CanActivate {
    /**
     * Class constructor
     * @param {Router} router
     */
    constructor(private authService: AuthService, public router: Router) {
    }
    /**
     * Method to evaluate whether page access is allowed
     * @return {boolean}
     */
    canActivate(): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            if (!this.authService.isRegistered()) {
                this.router.navigate(['auth/sign-up']);
                return false;
            } else {
                this.router.navigate(['auth/sign-in']);
                return false;
            }
        }
    }
}

