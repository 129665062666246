import { Injectable } from '@angular/core';

/**
 * This service should be used incase we need to refer window object.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  public nativeWindow: any;
  public nativeDocument: any;

  constructor() {
    this.nativeWindow = window;
    this.nativeDocument = document;
  }

  isPathExists(subPath: string) {
    if (this.nativeWindow.location.href.indexOf(subPath) !== -1) {
      return true;
    }
    return false;
  }

  getQueryStringValue(key: string) {
    const searchParams = this.nativeWindow.location.href.substring(this.nativeWindow.location.href.indexOf('?') + 1,
      this.nativeWindow.location.href.length);
    const queryString = new URLSearchParams(searchParams);
    return queryString.get(key);
  }
}
