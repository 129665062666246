import { WindowRefService } from '@core/window-ref.service';
import * as i0 from "@angular/core";
import * as i1 from "./window-ref.service";
/**
 * Now cookie management service
 */
export class CookieService {
    constructor(window) {
        this.window = window;
    }
    setCookie(name, value, expiryDate, path = '') {
        this.window.nativeDocument.cookie = `${name}=${value};expires=${expiryDate};path=${path}`;
    }
    getCookie(name) {
        const regexp = new RegExp(name + '=([^;]+)');
        return regexp.exec(this.window.nativeDocument.cookie);
    }
}
CookieService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CookieService_Factory() { return new CookieService(i0.ɵɵinject(i1.WindowRefService)); }, token: CookieService, providedIn: "root" });
