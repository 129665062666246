import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
      providedIn: 'root'
    }
)
export class ManualShareService {
  // Observable string sources
  private removeUnsavedSideKickSource = new Subject<number>();
  private enableSavedSidekickSource = new Subject<number>();
  private disableSavedSidekickSource = new Subject<number>();
  private changeManualHotspotImageSource = new Subject<any>();
  private changeManualHotspotTypeSource = new Subject<any>();
  private deleteManualHotSpotSource = new Subject<any>();
  // create observables vars
  removeUnsavedSideKick$ = this.removeUnsavedSideKickSource.asObservable();
  enableSavedSidekick$ = this.enableSavedSidekickSource.asObservable();
  disableSavedSidekick$ = this.disableSavedSidekickSource.asObservable();
  changeManualHotspotImage$ = this.changeManualHotspotImageSource.asObservable();
  changeManualHotspotType$ = this.changeManualHotspotTypeSource.asObservable();
  deleteManualHotSpot$ = this.deleteManualHotSpotSource.asObservable();
  /**
   * @description: method will use to pass data to child
   * component
   */
  removeUnsavedSideKickFun(sidekickObj: any) {
    this.removeUnsavedSideKickSource.next(sidekickObj);
  }
  /**
   * @description: Method will use to enable sidekick checkbox
   * @param sidekickImgId SideKick
   */
  enableSavedSidekickFun(sidekickImgId: number) {
    this.enableSavedSidekickSource.next(sidekickImgId);
  }
  /**
   * @description: Method will use to disable sidekick checkbox
   * @param sidekickImgId SideKick
   */
  disableSavedSidekickFun(sidekickImgId: number) {
    this.disableSavedSidekickSource.next(sidekickImgId);
  }
  changeHotspotImageFun(sidekickObj: any) {
    this.changeManualHotspotImageSource.next(sidekickObj);
  }

  changeHotspotImageType(selectectedCategoryType) {
    this.changeManualHotspotTypeSource.next(selectectedCategoryType);
  }

  deleteManualHotSpotFun() {
    this.deleteManualHotSpotSource.next();
  }
}
