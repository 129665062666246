import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export class ToasterService {
    constructor(toastr) {
        this.toastr = toastr;
        this.toasterOptions = {
            timeOut: 2000,
            positionClass: 'toast-bottom-center'
        };
    }
    success(title, body) {
        this.toastr.success(title, body, this.toasterOptions);
    }
    error(title, body) {
        this.toastr.error(title, body, this.toasterOptions);
    }
    info(title, body) {
        this.toastr.info(title, body, this.toasterOptions);
    }
    warning(title, body) {
        this.toastr.warning(title, body, this.toasterOptions);
    }
}
ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastrService)); }, token: ToasterService, providedIn: "root" });
