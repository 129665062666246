import { Injectable } from '@angular/core';
import { WindowRefService} from '@core/window-ref.service';
/**
 * Now cookie management service
 */
@Injectable({
    providedIn: 'root'
})
export class CookieService {

  constructor(private window: WindowRefService) { }

  public setCookie(name: string, value: string, expiryDate: string, path: string = '') {
    this.window.nativeDocument.cookie = `${name}=${value};expires=${expiryDate};path=${path}`;
  }

  public getCookie(name: string) {
    const regexp = new RegExp(name + '=([^;]+)');
    return regexp.exec(this.window.nativeDocument.cookie);
  }

}
