import {Component, ViewChild, TemplateRef } from '@angular/core';
import { MousePosition, Rectangle, Shape } from '../model/shape';

import { ManualHotspotService } from '../manual-hotspot.service';
import {ManualShareService} from '@shared/services/manual.shared.service';
import {FeatureCommService} from '../../../../main/dashboard/shared/services/feature-comm.service';

@Component({
    selector: 'app-rectangle',
    templateUrl: './rectangle-component.html',
})
export class RectangleComponent {
  @ViewChild('shapeTemplate', {static: true}) shapeTemplate: TemplateRef<any>;
  rectangleSelected: any = '';
  public shape: Shape;
  shapeType = 'Rectangle';
  offset: MousePosition;
  isSelected = false;
  selectionPoints: MousePosition[] = [];
  deleteComponent = false;
  id: number;
  hotspotId: number = null;
  sidekickId: number = null;
  sideKickImageId: number = null;
  isSaved = false;
  isMouseDown = false;
  footerHeight = 0;
  startDrawingFlag = false;
  tempHeight: number = null;
  tempOriginY: number = null;
  categoryType: number;

  constructor(private rectangleService: ManualHotspotService,
              private manualService: ManualShareService,
              public featureCommService: FeatureCommService) {
      this.shape = new Rectangle();
  }

  setStyles() {
    let styles = {};
    if (this.isSelected) {
        styles = {
        stroke : 'rgba(32, 138, 32, 0.7)',
        fill : 'rgba(32, 138, 32, 0.7)',
        'stroke-width': 3,
        width: this.shape.width,
        height: this.shape.height,
      };
    } else {
        styles = {
        stroke : this.shape.shapeProperties.strokeColor,
        fill : this.shape.shapeProperties.fillColor,
        'stroke-width': this.shape.shapeProperties.strokeWidth,
        width: this.shape.width,
        height: this.shape.height,
      };
    }
    return styles;
  }
  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof Rectangle) {
      this.startDrawingFlag = true;
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
    }
  }

  draw(currentPosition: MousePosition, imageAttributes): void {
    if (this.shape instanceof Rectangle) {
      if ((this.shape.width + this.shape.originX) <= 370
      && (this.shape.height + this.shape.originY) <= (664 - this.footerHeight)) {
        this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
        this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
      }
    }
    this.featureCommService.calculateCoordinatesinPercent(this.shape, imageAttributes);
  }
  drag(draqPosition: MousePosition, imageAttributes): void {
    if (this.offset === undefined) {
      this.offset = Object.assign({}, draqPosition);
      this.offset.x -= this.shape.originX;
      this.offset.y -= this.shape.originY;
    }
    /**
     * set dragPosX and offsetX as 0, to now allow user
     * to drag hotspot outside on left side
     */
    let dragPosX = draqPosition.x;
    let offsetX = this.offset.x;
    if ((draqPosition.x - this.offset.x) <= 0) {
      dragPosX = 0;
      offsetX = 0;
    }
    /**
     * set dragPosY and offsetY as 0, to now allow user
     * to drag hotspot outside on top side
     */
    let dragPosY = draqPosition.y;
    let offsetY = this.offset.y;
    if ((draqPosition.y - this.offset.y) <= 0) {
      dragPosY = 0;
      offsetY = 0;
    }
    let currentSize = imageAttributes;
    if (((dragPosY - offsetY) >= 0) && ((dragPosX - offsetX) >= 0)
    && (((dragPosY - offsetY) + this.shape.height) <= currentSize.height - this.footerHeight)
    && (((dragPosX - offsetX) + this.shape.width) <= currentSize.width)) {
      this.shape.originX = (dragPosX - offsetX);
      this.shape.originY = (dragPosY - offsetY);
    }
    this.featureCommService.calculateCoordinatesinPercent(this.shape, imageAttributes);
  }
  resizeShape(event: any, resizePosition: MousePosition, imageAttributes) {
    if (this.offset === undefined) {
      this.offset = Object.assign({}, resizePosition);
      this.offset.x -= this.shape.originX;
      this.offset.y -= this.shape.originY;
    }
    if ((resizePosition.x - this.shape.originX >= 46) && (resizePosition.y - this.shape.originY >= 46)) {
      this.shape.width = resizePosition.x - this.shape.originX;
      if (event.shiftKey) {
        this.shape.height = this.shape.width;
      } else {
        this.shape.height = resizePosition.y - this.shape.originY;
      }
    }
    this.featureCommService.calculateCoordinatesinPercent(this.shape, imageAttributes);
  }
  rectangleShapeSelect(rect): void {
    this.rectangleSelected = rect;
  }

  setPoint(point: MousePosition): void {
    // This is setPoint
  }
}
