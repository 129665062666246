<ng-template #shapeTemplate>
    <svg:rect attr.id="{{shape.shapeProperties.name}}" fill="url(#img1)" class="draggable" attr.x="{{ shape.originX }}" attr.y="{{ shape.originY }}"  [ngStyle]="setStyles()">
        <title>{{ shape.shapeProperties.name }}
        </title>
    </svg:rect>
      <svg xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"  
        attr.x="{{ shape.originX}}"
        attr.y="{{ shape.originY}}" 
        attr.width="{{ shape.width}}" 
        attr.height="{{ shape.height}}"
        (click)="rectangleShapeSelect(shape.shapeProperties.name)"
        [ngClass]="!featureCommService.dlsPresent ? 'draggable drag-cursor' : ''"
        [class.drag-cursor]="isSelected">
        <image class="draggable" attr.id="{{shape.shapeProperties.name}}"
        attr.xlink:href="{{shape.imgUrl}}" 
          [ngStyle]="{width: shape.width, height: shape.height}"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" *ngIf="isSelected"
            xmlns:xlink="http://www.w3.org/1999/xlink"  
            attr.x="{{ shape.originX + shape.width - 4 }}"
              [ngClass]="!featureCommService.dlsPresent ? 'resize' : ''" 
              attr.y="{{ shape.originY + shape.height - 4 }}"
              width="20"
              height="20">
              <ng-container *ngIf="!featureCommService.dlsPresent">
                <image class="resize" attr.id="{{shape.shapeProperties.name}}"
                xlink:href="../../../assets/images/resize.png" 
                  height="20" width="20"/>
              </ng-container>    
      </svg>
    <ng-container *ngIf="!featureCommService.dlsPresent">
      <svg xmlns="http://www.w3.org/2000/svg" *ngIf="isSelected"
        xmlns:xlink="http://www.w3.org/1999/xlink"  
        attr.x="{{ shape.originX + shape.width - 18 }}"
        attr.y="{{ shape.originY - 2 }}"
          class="delete" 
          width="20"
          height="20"
          style="cursor:pointer;">
            <image class="delete" attr.id="{{shape.shapeProperties.name}}"
            xlink:href="../../../assets/images/delete.png" 
            height="20" width="20"/>
        </svg>
    </ng-container>
</ng-template>
