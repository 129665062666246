import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {BaseService} from '@core/base.service';
import {Constants} from '@shared/services/constants.service';
import { ToasterService} from '@core/toaster.service';

import {CookieService} from '@core/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(
      public router: Router,
      public toaster: ToasterService,
      private http: HttpClient,
      private cookie: CookieService) {
    super(router, toaster);
  }

  login(userObj: any): Observable<any> {
    const url = this.baseUrl + Constants.apiEndPoints.signin;
    return this.http.post(url, userObj);
  }

  signup(userObj: any): Observable<any> {
    const url = this.baseUrl + Constants.apiEndPoints.signup;
    return this.http.post(url, userObj);
  }

  mailAuthenticate(token: any): Observable<any> {
    const url = this.baseUrl + Constants.apiEndPoints.authenticate;
    return this.http.post(url, {confirmation_token: token});
  }

  logout(): Observable<any> {
    const url = this.baseUrl + Constants.apiEndPoints.signout;
    return this.http.delete(url);
  }

  public isAuthenticated(): boolean {
    return (this.getAuthToken()) ?  true : false;
  }

  public isRegistered(): boolean {
    const cookieName = 'buildernow_registereduser';
    const result = this.cookie.getCookie(cookieName);
    return (result === null) ? false : true;
  }
}
