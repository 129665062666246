import { Injectable } from '@angular/core';
import { Feature, FeatureShort } from '../models/feature.model';
import { SidekickFeature } from '../models/sidekick-feature.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureCommService {
  isDataChanged: boolean;
  selectedFeature: Feature;
  selectedFeatureId: number;
  featureList: FeatureShort[];
  types =  [];
  industries = [];
  defaultIndustry;
  defaultIndustryTitle;
  defaultIndustryId;
  sidekickFeatureList: SidekickFeature[] = [];
  selectedFeatureSubject: Subject<Feature> = new Subject();
  selectedFeature$ = this.selectedFeatureSubject.asObservable();
  retainTab = false;
  categoryTypeTempArr = [];
  categoryTypeIDTempArr = [];
  categoryType: any;
  selectedCategoryType;
  version: string;
  totalHotspot = 0;
  industryTypeObj;
  showSyncPopup = false;
  public deletedManualHotspotIdArray: any = [];
  selectedAppId = 0;
  heroImageUpdated: Subject<boolean> = new Subject();
  verticalList: any;
  defaultVertical: number;
  defaultSubVertical: number;
  public dlsPresent: boolean = false;
  componentSelectedSub: Subject<any> = new Subject();
  hotspotSelectedSub: Subject<any> = new Subject();
  constructor() {
    // dp nothing
  }

  setSelectedFeature(feature: Feature) {
    this.selectedFeatureSubject.next(feature);
    this.selectedFeatureId = feature.id;
  }
  setFeatureList(featureList: FeatureShort[]) {
    this.featureList = featureList;
  }

  getFeatureList(): FeatureShort[] {
    return this.featureList;
  }

  setSidekickFeatures(sidekickFeatures: SidekickFeature[]) {
    this.sidekickFeatureList = sidekickFeatures;
  }

  getSidekickFeatures(): SidekickFeature[] {
    return this.sidekickFeatureList;
  }

  setDataChanged(flag: boolean) {
    if (this.isDataChanged !== flag) {
      this.isDataChanged = flag;
    }
  }

  setHeroImageUpdated() {
    this.heroImageUpdated.next(true);
  }

  calculateCoordinatesinPercent(shape, currentSize) {
    shape.percent_originX = (shape.originX/currentSize.width) * 100;
    shape.percent_originY = (shape.originY/currentSize.height) * 100;
    shape.percent_width = (shape.width/currentSize.width) * 100;
    shape.percent_height = (shape.height/currentSize.height) * 100;
  }
}
