/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page404.component";
var styles_Page404Component = [i0.styles];
var RenderType_Page404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Page404Component, data: {} });
export { RenderType_Page404Component as RenderType_Page404Component };
export function View_Page404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "error-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The page you were looking "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" for does not exist."])), (_l()(), i1.ɵeld(5, 0, null, null, 17, "div", [["class", "full-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", "left left-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Try This:"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u2022 If you typed in an URL, double-check the spelling. "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u2022 If you are logged in user, click "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "primary-color"], ["href", "/#/app"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, [" to goto dashboard. "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u2022 Or click "])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "a", [["class", "primary-color"], ["href", "/#/auth/sign-in"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, [" to goto Login. "]))], null, null); }
export function View_Page404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page404", [], null, null, null, View_Page404Component_0, RenderType_Page404Component)), i1.ɵdid(1, 114688, null, 0, i2.Page404Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Page404ComponentNgFactory = i1.ɵccf("app-page404", i2.Page404Component, View_Page404Component_Host_0, {}, {}, []);
export { Page404ComponentNgFactory as Page404ComponentNgFactory };
