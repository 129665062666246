export class Constants {
  static apiEndPoints = {
    signin: '/users/sign_in.json',
    signup: '/users.json',
    authenticate: '/users/token_confirmation.json',
    signout: '/users/sessions.json',
    refreshToken: '/users/sessions.json',
    featuresGet: '/api/v1/features.json',
    featuresPut: '/api/v1/features',
    featureGet: '/api/v1/features',
    apps: '/api/v1/applications',
    syncCpeTemplates: '/api/v1/cms/build_cards/sync_cpe_templates',
    getVerticals: '/api/v1/external/verticals/verticals_with_subverticals',
    fetchHeroImage: '/api/v1/features',
    updateDestinations: '/api/v1/cms/feature_hotspot_mappings/update_destinations'
  };
  static messages = {
    headerText: 'Changes not saved',
    mainText: 'Please save your changes, to avoid misouts',
    cancelText: 'Cancel',
    confirmText: 'Okay',
    confirmClass: 'error'
  };
  static deleteMessages = {
    headerText: 'Are you sure',
    mainText: 'If your agree, the contents will be deleted',
    cancelText: 'Cancel',
    confirmText: 'Okay',
    confirmClass: 'error'
  };
}
