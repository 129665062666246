import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { AuthGuardService as AuthGuard} from '@core/auth-guard.service';
import { AppGuardService as AppGuard } from '@core/app-guard.service';

const appRoutes: Routes = [
  { path : '', redirectTo: 'app', pathMatch: 'full' },
  { path : 'sign-in', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', canActivate: [AuthGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', canActivate: [AppGuard], loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path:  '**',  component: Page404Component }
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });



