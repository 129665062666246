<div class="error-page">
  <h1>The page you were looking <br> for does not exist.</h1>
  <div class="full-content">
    <div class="left left-content"><br />
      <div class="sub-title">Try This:</div><br />
      <p>
        • If you typed in an URL, double-check the spelling.
      </p>
      <p>
        • If you are logged in user, click <a class="primary-color" href="/#/app">here</a> to goto dashboard.
      </p>
      <p>
        • Or click <a class="primary-color" href="/#/auth/sign-in">here</a> to goto Login.
      </p>
    </div>
  </div>
</div>