import * as i0 from "@angular/core";
export class ManualHotspotService {
    constructor() {
        this.shapesComponents = [];
        // do nothing
    }
    getRectangleComponents() {
        return this.shapesComponents;
    }
    removeSelectedRectangleComponent(component) {
        const comps = this.getRectangleComponents();
        const index = comps.indexOf(component, 0);
        if (index > -1) {
            comps.splice(index, 1);
        }
    }
    removeAllRectangleComponents() {
        this.shapesComponents = [];
    }
    setRectangleComponent(component) {
        this.selectedComponent = component;
        this.shapesComponents.push(component);
    }
    getRectangleComponent() {
        return this.selectedComponent;
    }
    findRectangleComponent(name) {
        return this.shapesComponents.find(x => x.shape.shapeProperties.name === name);
    }
}
ManualHotspotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualHotspotService_Factory() { return new ManualHotspotService(); }, token: ManualHotspotService, providedIn: "root" });
