import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class FeatureCommService {
    constructor() {
        this.types = [];
        this.industries = [];
        this.sidekickFeatureList = [];
        this.selectedFeatureSubject = new Subject();
        this.selectedFeature$ = this.selectedFeatureSubject.asObservable();
        this.retainTab = false;
        this.categoryTypeTempArr = [];
        this.categoryTypeIDTempArr = [];
        this.totalHotspot = 0;
        this.showSyncPopup = false;
        this.deletedManualHotspotIdArray = [];
        this.selectedAppId = 0;
        this.heroImageUpdated = new Subject();
        this.dlsPresent = false;
        this.componentSelectedSub = new Subject();
        this.hotspotSelectedSub = new Subject();
        // dp nothing
    }
    setSelectedFeature(feature) {
        this.selectedFeatureSubject.next(feature);
        this.selectedFeatureId = feature.id;
    }
    setFeatureList(featureList) {
        this.featureList = featureList;
    }
    getFeatureList() {
        return this.featureList;
    }
    setSidekickFeatures(sidekickFeatures) {
        this.sidekickFeatureList = sidekickFeatures;
    }
    getSidekickFeatures() {
        return this.sidekickFeatureList;
    }
    setDataChanged(flag) {
        if (this.isDataChanged !== flag) {
            this.isDataChanged = flag;
        }
    }
    setHeroImageUpdated() {
        this.heroImageUpdated.next(true);
    }
    calculateCoordinatesinPercent(shape, currentSize) {
        shape.percent_originX = (shape.originX / currentSize.width) * 100;
        shape.percent_originY = (shape.originY / currentSize.height) * 100;
        shape.percent_width = (shape.width / currentSize.width) * 100;
        shape.percent_height = (shape.height / currentSize.height) * 100;
    }
}
FeatureCommService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureCommService_Factory() { return new FeatureCommService(); }, token: FeatureCommService, providedIn: "root" });
