import {NgModule} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';


@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSidenavModule

    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatDialogModule
    ]
})

export class MaterialModule {}

